function Footer() {
  return (
    <div
      style={{
        position: "fixed",
        right: 1,
        bottom: 5,
        color: "white",
        fontSize: 10,
        zIndex: 100,
      }}
    >
      © 2024. LeeDanbee KwonBowon ChoiNagyeong All rights reserved.
    </div>
  );
}

export default Footer;
