import Footer from "../components/Footer";
import Header from "../components/header/Header";
import Main from "../components/main/Main";
function Home() {
  return (
    <>
      <Header />
      <Main />
      <Footer />
    </>
  );
}

export default Home;
