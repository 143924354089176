import Footer from "../components/Footer";
import Header from "../components/header/Header";
function Recommand() {
  return (
    <>
      <Header />
      <div>recommand</div>
      <Footer />;
    </>
  );
}

export default Recommand;
