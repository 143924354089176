import ContactUI from "./ContactUI";

function ContactMain() {
  return (
    <div>
      <ContactUI />
    </div>
  );
}

export default ContactMain;
